<template>
  <div class="cats">
    <section :style="{ backgroundImage: 'url(' + cat.img + ')' }"
             class="wrapper image-wrapper bg-image bg-overlay bg-overlay-400 text-white px-0 mt-0">
      <div class="container pt-20 pt-md-25  pb-0 text-center">
        <div class="row">
          <div class="col-lg-12">
            <div class="post-header tyfnEdit">
              <h1 class="display-1 mb-3 white">{{ cat.name }}</h1>
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ol class="breadcrumb text-white">
                  <li class="breadcrumb-item">
                    <router-link to="/">{{ langcontent.home }}</router-link>
                  </li>
                  <li aria-current="page" class="breadcrumb-item active">{{ cat.name }}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <template v-if="prod != null">
      <template v-for="item in data.slice().reverse()">

        <section class="wrapper bg-light">
          <div class="container pt-10 pb-3">
            <div class="grid grid-view projects-masonry">
              <div class="row gx-md-8 gy-10 gy-md-13 isotope">
                <div class="project item col-md-12 workshop">
                  <div class="project-details d-flex justify-content-center flex-column">
                    <div class="post-header">
                      <h1 class="post-title h1 tyfnEdit">{{ item.title }}</h1>
                      <p class="descContent" v-html="item.desc0"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
    </template>
  </div>
</template>
<script>
import iso from "../axios";

export default {
  name: 'Cat',
  data() {
    return {
      cat: '',
      data: [],
      langcontent: [],
    }
  },

  methods: {
    pGet() {
      iso.get('langfix')
          .then(response => {
            this.langcontent = response.data[0]
          })
          .catch(error => {
            console.log(error);
          })
      // this.catName = this.$route.params.cat

    },
  },
  computed:{
    prod(){
      var id
      iso.get('cats/' + this.$route.params.cat)
          .then(response => {
            this.cat = response.data
            id = response.data.id
            document.title = 'Bimark | ' + this.cat.name;
            iso.get('products')
                .then(response => {
                  this.data = response.data.filter(c => c.cat === id)
                })
                .catch(error => {
                  console.log(error);
                })
          })
          .catch(error => {
            console.log(error);
          })
      return 'loaded'
    },
  },
  created() {
  },
  mounted() {
    this.pGet()
  },
  watch: {
    $route(to, from) {
      this.pGet()
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
};
</script>
<style>
.white {
  color: #fff;
}
.descContent,
.descContent *{
  line-height: 18px;
  font-size: 16px;
}
.descContent b{
  position: relative;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.descContent u{
  font-size: 14px!important;
  display: block;
  color: rgba(255, 0, 0, 0.67);
  margin-top: 10px;
}
.descContent br{
  display: none;
}

</style>
